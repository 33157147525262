import React, { useEffect, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import clsx from 'clsx'
import Vivus from 'vivus'

import Background1 from '@static/img/tracklines/backgrounds/Randlinie1.svg'
import Background2 from '@static/img/tracklines/backgrounds/Randlinie2.svg'
import Background3 from '@static/img/tracklines/backgrounds/Randlinie3.svg'
import Background4 from '@static/img/tracklines/backgrounds/Randlinie4.svg'
import Background5 from '@static/img/tracklines/backgrounds/Randlinie5.svg'

function TracklineBackground({ className, track, width, right, center }) {
  const VivusRef = useRef(null)
  const Wrapper = useRef(null)
  const [VivusObj, setVivusObj] = useState(0)
  const [distance, setDistance] = useState(0)
  const [translate, setTranslate] = useState(0)

  const actualWidth = width || 500
  const widthModifier = actualWidth * 0.65 // shift the lines by XX% out of the view
  const showAnimationModifier = actualWidth * 0.75 // hide the lines, if ony 100 - XX% would be visible

  const showAnimation = useMemo(() => {
    return distance > actualWidth - showAnimationModifier
  }, [distance, actualWidth, showAnimationModifier])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (VivusRef.current && !VivusObj) {
      // will not work in IE11
      try {
        setVivusObj(
          new Vivus(
            VivusRef.current,
            { type: 'oneByOne', duration: 200, file: renderSVG() },
            null
          )
        )
      } catch (e) {
        console.log(e)
      }
    }

    if (VivusObj) VivusObj.stop().reset().play()
    if (!showAnimation && VivusObj) {
      VivusObj.destroy()
      setVivusObj(null)
    }
  }, [distance])

  function handleResize() {
    const distance = right
      ? window.innerWidth - Wrapper.current.getBoundingClientRect().right
      : Wrapper.current.getBoundingClientRect().left

    setDistance(distance)
    setTranslate(
      right ? -actualWidth + distance + widthModifier : -widthModifier
    )
  }

  function renderSVG() {
    switch (track) {
      case 1:
        return Background1
      case 2:
        return Background2
      case 3:
        return Background3
      case 4:
        return Background4
      case 5:
        return Background5
      default:
        return Background1
    }
  }

  return (
    <div
      className={clsx(className, 'absolute')}
      style={{
        width: `${distance}px`,
        transform: `translateX(${right ? '100%' : '-100%'}) ${
          center ? 'translateY(-50%)' : ''
        }`,
      }}
      ref={Wrapper}
      data-testid="tracklinewrapper"
      tabIndex={-1}
      aria-hidden="true"
    >
      {showAnimation && (
        <div
          data-testid="vivus"
          className="vivus"
          ref={VivusRef}
          style={{
            transform: `translateX(${translate}px`,
          }}
        ></div>
      )}
    </div>
  )
}

TracklineBackground.propTypes = {
  className: PropTypes.string,
  track: PropTypes.number.isRequired,
  width: PropTypes.number,
  top: PropTypes.string,
  bottom: PropTypes.string,
  center: PropTypes.bool,
  right: PropTypes.bool,
  mirror: PropTypes.bool,
}

export default styled(TracklineBackground)`
  overflow-x: hidden;

  svg {
    width: auto;
    height: auto;
  }

  ${({ top, bottom, center }) => {
    if (bottom) {
      return css`
        bottom: ${bottom};
      `
    } else if (top || center) {
      return css`
        top: ${top ? top : '50%'};
      `
    } else {
      return css`
        top: 0;
      `
    }
  }};

  ${({ right }) => {
    if (right) {
      return css`
        right: 0;
      `
    } else {
      return css`
        left: 0;
      `
    }
  }}

  height: auto;

  transform: ${({ center }) => (center ? 'translateY(-50%)' : 'translateY(0)')};

  .vivus {
    width: ${({ width }) => (width ? `${width}px` : '500px')};
    height: 100%;

    svg {
      transform: ${({ right, mirror }) =>
        `scale(${right ? '-1' : '1'}, ${mirror ? '-1' : '1'})`};
    }
  }
`
